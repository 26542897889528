<template>
  <section>
    <b-card>
      <b-overlay :show="show">
        <b-row>
          <b-col></b-col>
          <b-col cols="1">
            <b-button-group>
              <b-button variant="primary" @click="getAll">Getir</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

    <ConfirmStock v-for="stock in _activeStock" :stock="stock" :key="stock.id"/>

  </section>
</template>

<script>
import ConfirmStock from "@/views/v2/stock/confirm/ConfirmStock.vue";
import {mapGetters} from "vuex";

export default {
  name: "ComfirmHome",
  components: {ConfirmStock},
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters('activeStock', ['_activeStock'])
  },
  methods: {
    getAll() {
      this.show = true
      this.$store.dispatch('activeStock/getAll')
          .then(res => {
            this.show = false;
          })
    }
  }
}
</script>

<style scoped>

</style>