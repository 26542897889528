<template>
  <b-card>
    <b-overlay :show="show">
      <b-row>
        <b-col cols="2">
          <div class="imageContainer m-0 p-0" v-if="stock.product && stock.product.images">
            <div class="clearfix border" v-if="stock.product.images[0]">
              <b-img class="border border-primary"
                     :src="'https://api.kitapfirsatlari.com/' + stock.product.images[0].url"
                     width="150"/>
            </div>
          </div>
        </b-col>
        <b-col cols="3">
          <b>{{ stock.product.name }}</b>
          <b-badge v-if="stock.product.brand">{{ stock.product.brand.name }}</b-badge>
          <br>
          barkod: {{ stock.product.barcode }}
          <hr>
          <b-badge variant="success">{{ stock.supplier.name }}</b-badge>
          <b-badge variant="secondary">{{ stock.stock_return.name }}</b-badge>
          <hr>
          Ekleyen:
          <b-badge v-if="stock.add_user">{{ stock.add_user.short_name }}</b-badge>
          Onaylayan:
          <b-badge v-if="stock.approver_user">{{ stock.approver_user.short_name }}</b-badge>
          <hr>
          Stok Adedi:
          <b-badge variant="danger" size="lg">{{ stock.quantity }}</b-badge>
          <hr>
          <div v-for="(orderProduct,index) in stock.order_products" :key="index" class="mt-1">
            <b-badge variant="danger">Sipariş : {{ orderProduct.count }}</b-badge>
            <b-badge variant="danger">Adet : {{ orderProduct.sum }}</b-badge>
          </div>
        </b-col>
        <b-col>
          <SupplierMatching :stock="stock"/>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import SupplierMatching from "@/views/v2/stock/SupplierMatching.vue";

export default {
  name: "ConfirmStock",
  components: {SupplierMatching},
  data: () => ({
    show: false,
  }),
  props: {
    stock: {
      type: Object,
      required: true
    }
  },
}
</script>